.certifications-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.certification-card,
.certification-item {
  border: 1px solid var(--secondary-color);
  padding: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primary-bg-color);
}

.certification-item {
  position: relative;
}

.certification-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  text-align: left;
}

.certification-title {
  color: var(--primary-color);
  margin: 0 0 10px 0;
  display: contents;
}

.certification-details p {
  font-size: var(--font-size-p);
  margin: 8px 0;
}

.certification-org,
.certification-date-emission,
.certification-date-expiration,
.certification-id {
  font-weight: 500;
}

.certification-link a {
  color: var(--highlight-color);
  text-decoration: underline;
  transition: color 0.3s;
}

.certification-link a:hover {
  color: var(--primary-color);
}
