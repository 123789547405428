.information-container {
  background-color: var(--primary-bg-color);
  border-radius: 50px;
  padding: 5px;
  /* margin-bottom: 20px; */
  font-weight: 501;
}

.information-columns {
  display: grid;
  grid-template-columns: 1.4fr 1fr 1fr 1fr;
  gap: 20px;
}

.information-columns p {
  display: flex;
  align-items: center;
}

.language-section,
.personal-section,
.contact-section,
.skills-section {
  display: flex;
  flex-direction: column;
}

.profile-photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.name {
  font-size: 24px;
}
.lastname {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-section h4,
.skills-section h4 {
  margin-top: 0;
  margin-bottom: 20px;
}

.contact-section a,
.other-networks a {
  color: #007bff;
  text-decoration: none;
}

.contact-section a:hover,
.other-networks a:hover {
  text-decoration: underline;
}

.skills-section > div:not(:last-child) {
  margin-bottom: 20px;
}

.information-container p {
  font-size: var(--font-size-p-info);
  /* margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
  margin: 0;
  /* TO FIX - ligne même hauteurs header info */
  height: 52.5px;
}

.personal-section h3 {
  font-size: var(--font-size-h2);
}

.introduction {
  /* gap: 20px; */
  /* padding: 20px; */
  font-weight: 501;
  height: 100%;
}

.introduction p {
  height: 100%;
  background-color: var(--secondary-bg-color); /* Couleur de fond gris clair */
  border-radius: 20px; /* Coins arrondis */
  padding: 10px 20px; /* Espacement intérieur */
  margin-right: 8px; /* Espacement entre les badges */
}
