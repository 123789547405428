/* Variables CSS pour le thème clair */
:root {
  /* clair */
  --cv-bg-color: #ffffff;
  --primary-bg-color: #e3e3e3;
  --secondary-bg-color: #d7d7d7;
  --primary-text-color: #333333;
  --secondary-text-color: #555555;
  --link-color: #007bff;
  --border-color: #e0e0e0;
  --icon-bg-color: #bdd5e9;
  --icon-fill-color: #000000;

  /* sombre */
  --cv-bg-color: #222430;
  --primary-bg-color: #181818;
  --secondary-bg-color: #303030;
  --primary-text-color: #e0e0e0;
  --secondary-text-color: #c0c0c0;
  --link-color: #2577b2;
  --border-color: #505050;
  --icon-bg-color: #222430;
  --icon-fill-color: #ffffff;

  --font-family: "Montserrat", "Arial", sans-serif;

  --coeff_reduction: 1;
  --base-font-size-h1: 2.5em;
  --base-font-size-h2: 1.875em;
  --base-font-size-h3: 1.25em;
  --base-font-size-h4: 0.9em;
  --base-font-size-p: 0.8em;
  --base-font-size-p-info: 1.25em;

  --font-size-h1: calc(var(--base-font-size-h1) * var(--coeff_reduction));
  --font-size-h2: calc(var(--base-font-size-h2) * var(--coeff_reduction));
  --font-size-h3: calc(var(--base-font-size-h3) * var(--coeff_reduction));
  --font-size-h4: calc(var(--base-font-size-h4) * var(--coeff_reduction));
  --font-size-p: calc(var(--base-font-size-p) * var(--coeff_reduction));
  --font-size-p-info: calc(
    var(--base-font-size-p-info) * var(--coeff_reduction)
  );
}

/* Autres paramètres */
body {
  background-color: var(--primary-bg-color);
  font-family: var(--font-family);
  color: var(--primary-text-color);
  margin: 0;
  padding: 0;
  text-align: justify;
}

.cv {
  /* margin: 2rem auto; */
  padding: 1rem;
  border: 1px solid var(--border-color);
  background-color: var(--cv-bg-color);

  /* Impression - à changer */
  /* xdpyinfo | grep dimensions */
  /* largeur écran 15 pouces : 345mm */
  width: 1168px;
  /* centre le body horizontalement si l'écran est plus large qu'une feuille A4 */
  margin: 0 auto;
  /* ajoute une ombre pour donner l'illusion d'une feuille de papier */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* fond blanc comme une feuille de papier */
  /* background-color: #fff; */
}

.info-personnelles,
.contact,
.permis,
.langues,
.autres-infos {
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 0;
}

.info-personnelles img {
  max-width: 100px;
  border-radius: 50%;
  float: left;
  margin-right: 2rem;
}

h3,
h4 {
  color: var(--primary-text-color);
  font-weight: bold;
}

p,
li {
  color: var(--secondary-text-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

li {
  margin-bottom: 0.2rem;
  font-size: var(--font-size-p);
}

address {
  font-style: normal;
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
  margin: 0;
}

.icon-default {
  background-color: var(--icon-bg-color);
  fill: var(--icon-fill-color);
}

/* Pour le thème sombre, il vous suffirait de changer les variables ci-dessus. Par exemple : */
/* 
  :root {
    --primary-bg-color: #2C2C2C;
    --secondary-bg-color: #1E1E1E;
    --primary-text-color: #E0E0E0;
    --secondary-text-color: #B0B0B0;
    --link-color: #58AFFF;
    --border-color: #444444;
    --font-family: 'Arial', sans-serif;
  } 
  */

/* Paramètres d'impression */
@media print {
  :root {
    --primary-bg-color: #f8f8f8;
    --secondary-bg-color: #eaeaea;
    --primary-text-color: #333333;
    --secondary-text-color: #555555;
    --link-color: #007bff;
    --border-color: #e0e0e0;
    --icon-bg-color: #ffffff;
    --icon-fill-color: #333333;

    --coeff_reduction: 0.6;
    --font-size-h1: calc(var(--base-font-size-h1) * var(--coeff_reduction));
    --font-size-h2: calc(var(--base-font-size-h2) * var(--coeff_reduction));
    --font-size-h3: calc(var(--base-font-size-h3) * var(--coeff_reduction));
    --font-size-h4: calc(var(--base-font-size-h4) * var(--coeff_reduction));
    --font-size-p: calc(var(--base-font-size-p) * var(--coeff_reduction));
    --font-size-p-info: calc(
      var(--base-font-size-p-info) * var(--coeff_reduction)
    );
  }

  body,
  html {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  .cv {
    width: 100%;
    margin: 0;
    box-shadow: none;
    background-color: #fff;
    /* TODO A remettre ensuite */
    border: 0;
    padding: 0;
  }

  .section {
    break-inside: avoid;
  }

  .no-print {
    display: none;
  }

  img {
    display: block;
  }

  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    print-color-adjust: exact !important; /* Firefox */
  }

  /* Exemple pour ne pas couper à l'impression */
  .experience-card,
  .formation-card {
    break-inside: avoid;
  }

  .formations-section,
  .hackathons-section,
  .certifications-section {
    break-inside: avoid;
  }

  /* .formations-section,
  .hackathons-section,
  .certifications-section {
    break-before: page;
    margin-top: 20px;
  } */
}
