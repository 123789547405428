.experiences-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.experience-card,
.experience-item {
  border: 1px solid var(--secondary-color);
  padding: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primary-bg-color);
}

.experience-item {
  position: relative;
}

.experience-header {
  display: flex;
  align-items: center;
  text-align: left;
}

.experience-title {
  color: var(--primary-color);
  margin: 0 0 10px 0;
  display: contents;
}

.experience-details p {
  font-size: var(--font-size-h4);
  margin: 8px 0;
}

.experience-company,
.experience-duration,
.experience-context {
  font-weight: 500;
}

.experience-tasks h4,
.experience-results h4,
.experience-tech h4,
.experience-skills h4 {
  color: var(--primary-color);
  font-size: var(--font-size-h4);
  margin-top: 20px;
}

.experience-tasks ul,
.experience-results ul,
.experience-tech ul,
.experience-skills ul {
  list-style-type: circle;
  padding-left: 20px;
}
