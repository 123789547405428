.hackathons-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.hackathon-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primary-bg-color);
  border: 1px solid var(--secondary-color);
  padding: 20px;
  border-radius: 30px;
}

.hackathon-item {
  position: relative;
}

.hackathon-title {
  color: var(--primary-color);
  margin: 0 0 10px 0;
}

.hackathon-date-location {
  font-size: var(--font-size-h4);
  margin: 8px 0;
  font-style: italic;
}

.hackathon-description,
.hackathon-role {
  font-weight: 500;
  margin: 8px 0;
}

.hackathon-description {
  background-color: var(--secondary-bg-color); /* Couleur de fond gris clair */
  border-radius: 8px; /* Coins arrondis */
  padding: 5px 10px; /* Espacement intérieur */
  margin-right: 8px; /* Espacement entre les badges */
}

.hackathon-details h4 {
  color: var(--highlight-color);
  margin: 10px 0;
  font-weight: bold;
}

.hackathon-results ul,
.hackathon-tech ul {
  list-style-type: circle;
  padding-left: 20px;
}

/* badge */
.hackathon-tech ul {
  display: flex;
  flex-wrap: wrap; /* Permet aux éléments de se déplacer à la ligne suivante si l'espace est insuffisant */
  padding: 0;
  list-style-type: none; /* Supprime les puces de la liste */
}

.hackathon-tech li {
  margin-right: 8px;
  margin-bottom: 8px;

  /* badges */
  background-color: var(--secondary-bg-color); /* Couleur de fond gris clair */
  border-radius: 8px; /* Coins arrondis */
  padding: 5px 10px; /* Espacement intérieur */
  margin-right: 8px; /* Espacement entre les badges */
  font-size: 12px; /* Taille du texte */
}
