.formations-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.formation-card,
.formation-item {
  border: 1px solid var(--secondary-color);
  padding: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--primary-bg-color);
}

.formation-item {
  position: relative;
}

.formation-header {
  display: flex;
  align-items: center;
  text-align: left;
}

.formation-title {
  color: var(--primary-color);
  margin: 0 0 10px 0;
  display: contents;
}

.formation-duration,
.formation-description,
.formation-mention {
  font-size: var(--font-size-h4);
  margin: 8px 0;
}

.formation-results h4,
.formation-tech h4 {
  color: var(--primary-color);
  font-size: var(--font-size-h4);
  margin-top: 20px;
}

.formation-results ul,
.formation-tech ul {
  list-style-type: circle;
  padding-left: 20px;
}
